$(document).on('turbo:load', function() { 
  console.log("XXXX");
  $("#categories").sortable({
    handle: '.handle',
    update: function(e, ui) {
      $.ajax({
        url: $(this).data("url"),
        type: "PUT",
        data: $(this).sortable('serialize'),
      });
    }
  });

  $("#sortable_accounts").sortable({
    handle: '.handle',
    update: function(e, ui) {
      $.ajax({
        url: $(this).data("url"),
        type: "PUT",
        data: $(this).sortable('serialize'),
      });
    }
  });

  $(document).on('click', '.delete-renewal-button', function(event) {
    console.log('asasasasa')
    var renewalId = $(this).data('renewal-id')
    $.ajax({
      url: '/renewals/' + renewalId,
      method: 'DELETE'
    })
  });

  $(document).on('click', '.delete-gas-task-button', function(event) {
    console.log('asasasasa')
    var taskId = $(this).data('gas-task-id')
    $.ajax({
      url: '/gas_tasks/' + taskId,
      method: 'DELETE'
    })
  });

  $(document).on('click', '.update-gas-button', function(event) {
    console.log('asasasasa')
    var taskId = $(this).data('gas-task-id')
    var value = $(this).data('value')
    $.ajax({
      url: '/gas_tasks/' + taskId,
      method: 'PUT',
      data: { value: value }
    })
  });

  $(document).on('click', '.hide-account-button', function(event) {
    console.log('qqqq')
    var accountId = $(this).data('account-id')
    var value = $(this).data('value')
    console.log(value)
    $.ajax({
      url: '/accounts/' + accountId,
      method: 'PUT',
      data: { value: value }
    })
  });

  $(document).on('click', '.delete-bid-button', function(event) {
    console.log('asasasasa')
    // Prevent default action
    event.preventDefault();
    $.ajax({
      url: $(this).data('url'),
      method: 'DELETE'
    })
  });
})
